import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';
import { textColor, primaryColor, primaryTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 0 16px;
    font-size: calc(${baseFontSize} * 1);
    color: ${textColor};
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${primaryColor};
      color: ${primaryTextColor};
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  & > div {
    &:nth-child(1) {
      font-size: calc(${baseFontSize} * 0.9);
    }
  }
`;