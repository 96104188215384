import { Company } from 'models/entities/company';

class Condition {

  readonly closed: boolean[];
  readonly deleted: boolean;

  constructor() {
    this.closed = [true, false];
    this.deleted = false;
  }

  filter(all: Company[]): Company[] {
    return all.filter(it => this.check(it));
  }

  private check(company: Company): boolean {
    return true;
  }

}

export { Condition };