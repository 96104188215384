import React, { FC, useState, useEffect, useRef } from 'react';

import { Company } from 'models/entities/company';
import { ActionName } from '../../models/action-name';
import { Model } from './index.model';

import { MoreActionsIcon } from 'views/components/icons';
import { Root } from './index.styled';

interface Props {
  target: Company;
  onSelect: (action: ActionName) => void;
}

const ActionsButton: FC<Props> = ({ target, onSelect }) => {

  const button = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [model, setModel] = useState(new Model({ target }));

  function toggle() {
    setIsOpen(!isOpen);
  }

  function close() {
    setIsOpen(false);
  }

  function watchClick(e: Event) {
    if (!button.current?.contains(e.target as Node)) close();
  }

  function watchTarget() {
    setModel(model.apply({ target }));
  }

  function watchIsOpen() {
    if (!isOpen) return;
    window.addEventListener('click', watchClick);
    return () => window.removeEventListener('click', watchClick);
  }

  useEffect(watchTarget, [target]);
  useEffect(watchIsOpen, [isOpen]);

  return (
    <Root>
      <button ref={button} onClick={() => toggle()}><MoreActionsIcon /></button>
      {isOpen && (
        <ol>
          <li onClick={() => onSelect('edit-user')}>Add User</li>
        </ol>
      )}
    </Root>
  );

};

export { ActionsButton };