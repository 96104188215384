import { Company, CompanyCollection } from 'models/entities/company';
import { User, UserEditor } from 'models/entities/user';

import { Condition } from './models/condition';
import { ReadGql } from './models/read-gql';

class Model {

  readonly condition: Condition;
  readonly collection?: CompanyCollection;
  readonly list?: Company[];

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const gql = await new ReadGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    const collection = new CompanyCollection(gql.result.collection);
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(): Company {
    return new Company();
  }

  add(company: Company): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(company));
  }

  replace(company: Company): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(company));
  }

  remove(company: Company): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(company));
  }

  private apply(collection: CompanyCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  buildUser(company: Company): User {
    return new User({ organizationId: company.id });
  }

  buildUserEditor(original: User): UserEditor {
    return new UserEditor({ original });
  }

  incrementUserCount(company: Company): this {
    return this.replace(company.incrementUserCount());
  }

}

export { Model };