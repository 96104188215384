import { Api } from 'models/services/api';
import { ReadCompaniesQuery } from 'models/services/graphql';
import { CompanyCollectionData } from 'models/entities/company';

const RequestQuery = `
  query Companies($nextToken: String) {
    ${ReadCompaniesQuery}
  }
`;

type ResponseData = {
  data: {
    readCompanies: CompanyCollectionData;
  }
}

type Result = {
  collection: CompanyCollectionData;
}

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(): Promise<this> {
    const api = await this.api.fetch();
    if (!api.response) throw new Error('invalid response');
    const collection = api.response.data.readCompanies;
    const result = { collection };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };