import { Company } from 'models/entities/company';

type Data = {
  target: Company;
};

type DataToApply = {
  target?: Company;
};

class Model {

  readonly target: Company;

  constructor(data: Data) {
    this.target = data.target;
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    return Object.assign(Object.create(this.constructor.prototype), { ...props });
  }

}

export { Model };