import React, { FC, useState, useEffect } from 'react';

import { Model } from './index.model';

import { showNumber } from 'views/services/helpers';
import { UserIcon } from 'views/components/icons';
import { Root, Layout } from './index.styled';

type Props = {
  userCount: number;
  onPush: () => void;
};

const UsersButton: FC<Props> = ({ userCount, onPush }) => {

  const [model, setModel] = useState(new Model({ userCount }));

  function watchUserCount() {
    setModel(model.apply({ userCount }));
  }

  useEffect(watchUserCount, [userCount]);

  return (
    <Root>
      <button onClick={() => onPush()}>
        <Layout>
          <div><UserIcon /></div>
          <div>{showNumber(userCount)}</div>
        </Layout>
      </button>
    </Root>
  );

};

export { UsersButton };