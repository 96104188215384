import React, { useState, useEffect } from 'react';

import { Company } from 'models/entities/company';
import { User } from 'models/entities/user';
import { ActionName } from './models/action-name';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useModal, EditUserTarget, ShowUsersTarget, ModalResult } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { UsersButton } from 'views/components/users-button';
import { ActionsButton } from './views/actions-button';
import { Root, Frame, List, ListHeader, ListItem, Label, Content, Actions } from './index.styled';

const Companies = () => {

  const auth = useAuth();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(company?: Company) {
    const target = company ?? model.build();
    const tell = apply;
    modal.open('edit-company', { target, strict: true, tell });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Company)) return;
    const company = result.target as Company;
    switch (result.state) {
      case 'created': setModel(model.add(company)); break;
      case 'updated': setModel(model.replace(company)); break;
      case 'deleted': setModel(model.remove(company)); break;
    }
  }

  function selectAction(action: ActionName, company: Company) {
    switch (action) {
      case 'edit-user': return editUser({ user: model.buildUser(company), company });
    }
  }

  function editUser({ user, company }: { user: User, company: Company }) {
    const target = new EditUserTarget({ user: model.buildUserEditor(user), organization: company });
    const tell = applyUser;
    modal.open('edit-user', { target, strict: true, tell });
  }

  function applyUser(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof EditUserTarget)) return;
    const { organization } = result.target;
    switch (result.state) {
      case 'created': setModel(model.incrementUserCount(organization as Company)); break;
    }
  }

  function showUsers(company: Company) {
    const target = new ShowUsersTarget({ organization: company });
    const tell = applyUsers;
    modal.open('show-users', { target, strict: true, tell });
  }

  function applyUsers(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof ShowUsersTarget)) return;
    const { organization } = result.target;
    switch (result.state) {
      case 'updated': setModel(model.replace(organization as Company)); break;
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} onCreate={() => edit()} />
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Company</NoInterfaceData> : (
            <List>
              <ListHeader>
                <Label>
                  <div>Name</div>
                </Label>
              </ListHeader>
              {model.list.map((it, i) => (
                <ListItem key={i}>
                  <Content onClick={() => edit(it)}>
                    <div>{it.name}</div>
                  </Content>
                  <Actions>
                    <div><UsersButton userCount={it.userCount} onPush={() => showUsers(it)} /></div>
                    <div><ActionsButton target={it} onSelect={action => selectAction(action, it)} /></div>
                  </Actions>
                </ListItem>
              ))}
            </List>
          )}
        </Frame>
      )}
    </Root>
  );

};

export default Companies;